// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onboarding-1-choose-template-js": () => import("./../src/pages/onboarding-1/choose-template.js" /* webpackChunkName: "component---src-pages-onboarding-1-choose-template-js" */),
  "component---src-pages-onboarding-1-custom-container-type-js": () => import("./../src/pages/onboarding-1/custom-container-type.js" /* webpackChunkName: "component---src-pages-onboarding-1-custom-container-type-js" */),
  "component---src-pages-onboarding-1-custom-node-type-js": () => import("./../src/pages/onboarding-1/custom-node-type.js" /* webpackChunkName: "component---src-pages-onboarding-1-custom-node-type-js" */),
  "component---src-pages-onboarding-1-done-js": () => import("./../src/pages/onboarding-1/done.js" /* webpackChunkName: "component---src-pages-onboarding-1-done-js" */),
  "component---src-pages-onboarding-1-index-js": () => import("./../src/pages/onboarding-1/index.js" /* webpackChunkName: "component---src-pages-onboarding-1-index-js" */),
  "component---src-pages-onboarding-1-tag-types-js": () => import("./../src/pages/onboarding-1/tag-types.js" /* webpackChunkName: "component---src-pages-onboarding-1-tag-types-js" */)
}

